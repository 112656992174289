<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list-item :key="patient.uid"
               :disabled="$store.state.layout.searchActive" three-line
               @click.stop="viewPatientProfile"
  >
    <v-list-item-avatar size="50">
      <user-avatar-img :user="patient" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="patient.full_name" />
      <v-list-item-subtitle v-if="patient.birth_date" class="secondary--text text--darken-1">
        {{ $dayjs(patient.birth_date).format('DD/MM/YYYY') }}
        ({{ $dayjs().diff(patient.birth_date, 'years') }} ans)
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else>Date de naissance non renseignée</v-list-item-subtitle>
      <address-displayer city-only :address="patient.address" />
    </v-list-item-content>
    <v-list-item-action class="align-self-center flex-row">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onAction }">
          <v-btn icon :disabled="!patient.phone" v-on="onAction" @click.stop="call(patient.phone)">
            <font-awesome-icon fixed-width size="lg" :icon="['fal', 'phone']" :class="!patient.phone ? 'grey--text' : 'primary--text'" />
          </v-btn>
        </template>
        <span>Appeler le patient</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onAction }">
          <v-btn icon :disabled="!patient.email" v-on="onAction" @click.stop="sendmail(patient.email)">
            <font-awesome-icon fixed-width size="lg" :icon="['fal', 'at']" :class="!patient.email ? 'grey--text' : 'primary--text'" />
          </v-btn>
        </template>
        <span>Envoyer un e-mail</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import AddressDisplayer from '@/modules/core/components/AddressDisplayer'
  import contactMethods from '@/mixins/contactMethods'
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'

  export default {
    name: 'PatientListItem',
    components: {UserAvatarImg, AddressDisplayer},
    mixins: [contactMethods],
    props: {
      patient: {
        type: Object,
        default: null
      }
    },
    data() {
      return {}
    },
    methods: {
      viewPatientProfile(){
        this.$router.push({name: 'patient-profile', params: {uid: this.patient.uid}})
      }
    }
  }
</script>

