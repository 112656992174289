<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <section>
    <v-card v-if="newPatients.length > 0" class="mb-9" shaped outline elevation="0">
      <v-toolbar dense flat>
        <v-badge v-if="displayNewEntries" bordered
                 bottom
                 color="secondary"
                 :content="newPatients.length"
                 :value="newPatients.length > 0"
                 offset-x="12"
                 offset-y="15"
        >
          <v-avatar size="35" color="primary">
            <font-awesome-icon size="lg" class="white--text" :icon="['far', 'smile-plus']" />
          </v-avatar>
        </v-badge>
        <v-toolbar-title class="ml-4 mt-1 font-weight-light" :class="displayNewEntries ? '' : 'op-40'">Récemment ajoutés</v-toolbar-title>
        <v-spacer />

        <v-btn icon small @click="displayNewEntries = !displayNewEntries">
          <font-awesome-icon size="2x" :icon="['fal', 'angle-down']" :class="displayNewEntries ? 'fa-rotate-180' : ''" />
        </v-btn>
      </v-toolbar>
      <template v-if="displayNewEntries">
        <v-divider />
        <v-list v-if="newPatients.length !== 0" three-line class="py-0">
          <template v-for="(patient, index) in newPatients">
            <patient-list-item :key="patient.uid" :patient="patient" />
            <v-divider v-if="index + 1 < newPatients.length" :key="'divider-'+index" class="my-0" />
          </template>
        </v-list>
      </template>
    </v-card>
    <v-card class="elevation-x scroller-target">
      <v-sheet :color="$vuetify.theme.dark ? '' :'grey lighten-3'" class="pa-2">
        <v-row>
          <v-col cols="12" md="9">
            <v-text-field v-model="term" dense filled rounded placeholder="Recherche par nom" hide-details />
          </v-col>
          <v-col cols="12" md="3" class="d-none d-md-block">
            <v-select dense filled rounded placeholder="ordre d'affichage" :items="sortingTypes" hide-details />
          </v-col>
        </v-row>
      </v-sheet>
      <v-divider />
      <empty-list v-if="!loading && patients.length === 0" title="Aucun résultat"
                  subtitle="Vous n'avez aucun patient dans votre liste" actions="Créer un patient" @action-empty="addPatient"
      />
      <v-list v-else three-line class="py-0">
        <q-infinite-scroll ref="scroller" :offset="500" scroll-target=".scroller-target" @load="onLoad">
          <template v-for="(patient, index) in patients">
            <patient-list-item :key="patient.uid" :patient="patient" />
            <v-divider v-if="index + 1 < patients.length" :key="'divider-'+index" class="my-0" />
          </template>
          <template #loading>
            <v-progress-linear color="primary" indeterminate height="4" />
          </template>
        </q-infinite-scroll>
      </v-list>
    </v-card>
  </section>
</template>

<script>
  import EmptyList from '@/modules/core/layout/EmptyList'
  import {QInfiniteScroll} from 'quasar'
  import {mapGetters, mapState} from 'vuex'
  import PatientListItem from '@/modules/patients/components/professionals/PatientListItem'
  import {debounce} from 'throttle-debounce'
  import * as Patients from '@/modules/patients/api'
  import {_formatApiReturn} from '@/store/modules/patients'

  export default {
    name: 'PatientsList',
    components: {PatientListItem, QInfiniteScroll, EmptyList},
    data() {
      return {
        loading: true,
        searchResults: null,
        displayNewEntries: true,
        sortingTypes: ['Date d\'ajout', 'Nom de famille'],
        previousTerm: '',
        term: '',
        patients: []
      }
    },
    computed: {
      ...mapState('patients', ['search']),
      ...mapState('user/lists', ['newPatients']),
      ...mapGetters('patients', ['isLoaded'])
    },
    watch: {
      term: debounce(600, async function (v) {
        let term = v.length >= 3 ? v : ''
        if (this.previousTerm !== term) {
          this.previousTerm = term
          this.patients = []
          this.$refs.scroller.reset()
          this.$refs.scroller.resume()
        }
      })
    },
    async created() {
      if (this.patients.length) {
        this.loading = false
      }
    },
    methods: {
      async onLoad(index, done) {
        try {
          this.loading = true
          let searchTerm = this.term.length >= 3 ? this.term : null
          let {list, total} = (await Patients.list({mypatients : true, search_term: searchTerm },
                                                   {page: index, limit: 10, order: {lastName: 'ASC'}})).data
          let currentTerm = this.term.length >= 3 ? this.term : null
          if (currentTerm !== searchTerm) {
            done()
          }
          this.patients = this.patients.concat(list)
          done(this.patients.length >= total)
        } catch (e) {
          this.$handleApiError(e)
          done(true)
        } finally {
          this.loading = false
        }
      },
      addPatient() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/patients/components/PatientForm')
        })
      }
    }
  }
</script>
